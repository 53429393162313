import React from "react";
import "./styles.css"; // Include your Tailwind styles

//Azul Caelum #002e60
//Gris Caelum #b2b3b5

const ReservationPage = () => {
  return (
    <div className="bg-[#000000] text-white min-h-screen flex flex-col">
      <header className="flex justify-between items-center p-4">
        <img
          src="LogoCaelumResto.jpg"
          alt="Caelum Restó"
          className="w-48 h-48"
        />
        <nav>
          <a href="#menu" className="px-4">
            See Menu
          </a>
          <a href="#reservation" className="px-4">
            Book a Table
          </a>
        </nav>
      </header>

      {/* Seccion Hook */}
      <section className="flex flex-col items-center justify-center text-left px-0 bg-[#000000] md:flex-row md:justify-between">
        <div className="md:w-1/2">
          <h2 className="text-4xl font-semibold px-20">
            A Unique Dining Experience in the Heart of Mendoza
          </h2>
          <p className="max-w-xl px-20 mt-6 mb-6">
            Nestled within the stunning Caelum winery, Caelum Resto offers more
            than just a meal – we offer an unforgettable experience. Enjoy our
            open-air dining room with breathtaking views of the majestic Andes
            Mountains and serene pistachio orchards.
          </p>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="p-4 rounded-lg">
              <h3 className="text-lg font-bold px-20">Operating Hours</h3>
              <p className="px-20">Everyday: 12:00 AM - 18:00 PM</p>
              <p className="px-20">Wednesdays: Closed</p>
            </div>
          </div>
        </div>
        <div className="md:block md:w-1/2">
          <img
            src="/sillas.jpg"
            alt="[image-alt]"
            className="object-cover h-full w-full opacity-70"
          />
        </div>
      </section>

      {/* Seccion Terroire */}
      <section className="flex flex-col items-center justify-center md:flex-row md:space-x-4 text-center my-12 bg-[#000000]">
        <div className="md:w-1/2">
          <div className="flex items-center justify-center">
            <h2 className="text-3xl font-semibold mb-4">
              Authentic Terroir Menu
            </h2>
          </div>
          <div className="flex items-center justify-center">
            <p className="max-w-xl mb-6">
              At Caelum Resto, we pride ourselves on serving a "Terroir" menu,
              carefully curated with authentic Mendocinian ingredients and
              flavors. Each dish tells the story of the land, honoring the rich
              culinary traditions of Mendoza.
            </p>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="flex items-center justify-center">
            <h2 className="text-3xl font-semibold mb-4">
              Free-Flowing Wine Pairings
            </h2>
          </div>
          <div className="flex items-center justify-center">
            <p className="max-w-xl mb-6">
              Our wines flow freely, expertly paired with our seasonal menu to
              enhance every bite. As you savor the finest local produce, immerse
              yourself in the vibrant essence of Mendoza’s winemaking heritage.
            </p>
          </div>
        </div>
      </section>

      {/* Seccion Entradas */}
      <section className="flex flex-col items-center justify-center text-left px-0 mt-2 mb-2 md:flex-row md:justify-between md:mx-24">
        <div className="md:block md:w-1/2 pr-4">
          <img
            src="/entrada.jpg"
            alt="[image-alt]"
            className="object-cover h-full w-full opacity-70"
          />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-4xl font-semibold px-20 mb-4">Starters</h2>
          <p className="max-w-xl px-20 mb-6">
            Begin your culinary journey with our selection of locally inspired
            appetizers. From fresh, seasonal vegetables to artisanal cheeses,
            each dish is crafted to highlight the authentic flavors of Mendoza's
            terroir. Every bite is a harmonious blend of tradition and
            innovation, setting the perfect tone for your dining experience.
          </p>
        </div>
      </section>

      {/* Seccion Principal */}
      <section className="flex flex-col items-center justify-center text-left px-0 mt-2 mb-2 md:flex-row md:justify-between md:mx-24">
        <div className="md:w-1/2">
          <h2 className="text-4xl font-semibold px-20 mb-4">Mains</h2>
          <p className="max-w-xl px-20 mb-6">
            Our main courses bring the essence of the Andes to your table,
            featuring hand-picked ingredients from Mendoza’s fertile lands. Each
            dish is carefully prepared to showcase the unique flavors of the
            region, from tender meats to farm-fresh produce. Paired with our
            Caelum wines, every bite offers a true taste of Mendoza.
          </p>
        </div>
        <div className="md:block md:w-1/2 pl-4">
          <img
            src="/princ.jpg"
            alt="[image-alt]"
            className="object-cover h-full w-full opacity-70"
          />
        </div>
      </section>

      {/* Seccion Postres */}
      <section className="flex flex-col items-center justify-center text-left px-0 mt-2 mb-2 md:flex-row md:justify-between md:mx-24">
        <div className="md:block md:w-1/2 pr-4">
          <img
            src="/postres1.jpg"
            alt="[image-alt]"
            className="object-cover h-full w-full opacity-70"
          />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-4xl font-semibold px-20 mb-4">Desserts</h2>
          <p className="max-w-xl px-20 mb-6">
            End your meal on a high note with our selection of decadent
            desserts. Each creation is a tribute to Mendoza's sweetest
            offerings, using local fruits and flavors to craft the perfect
            finale to your culinary adventure. Enjoy a deliciously sweet moment
            as you savor the natural richness of the region.
          </p>
        </div>
      </section>

      <footer className="bg-000000 text-center p-4">
        <div className="md:flex md:justify-between md:space-x-4">
          <div className="md:w-2/3 md:pr-4 md:text-center md:flex md:items-center md:justify-center md:h-full">
            <div className="md:flex md:flex-col md:items-center md:justify-center">
              <h2 className="text-3xl font-semibold mb-4">
                Discover the Caelum Experience
              </h2>
              <p className="max-w-xl mb-6">
                Come for the food, stay for the view, and leave with memories
                that will last a lifetime. Whether you’re a wine enthusiast, a
                foodie, or simply looking to enjoy the beauty of nature, Caelum
                Resto is the perfect destination.
              </p>
            </div>
          </div>
          <div className="md:w-1/3 md:text-left md:mr-4">
            <h3 className="text-lg font-bold">Book a Table</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const message = `Name: ${e.target.name.value}%0ADate: ${e.target.date.value}%0APeople: ${e.target.people.value}`;
                window.open(
                  `https://wa.me/5493541596211?text=${message}`,
                  "_blank"
                );
              }}
            >
              <div className="flex flex-col">
                <label className="block text-sm font-bold" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div className="flex flex-col mt-4">
                <label className="block text-sm font-bold" htmlFor="date">
                  Date
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div className="flex flex-col mt-4">
                <label className="block text-sm font-bold" htmlFor="people">
                  Number of People
                </label>
                <input
                  type="number"
                  name="people"
                  id="people"
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md"
                  required
                />
              </div>
              <button
                type="submit"
                className="mt-4 w-full px-4 py-2 text-sm font-bold text-white bg-primary hover:bg-primary/90 rounded-md"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ReservationPage;
