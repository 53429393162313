import "./App.css";
//import LandingPage from "./landing";
import ReservationPage from "./landing2";

function App() {
  return (
    <div className="App">
      <ReservationPage />
      {/*<LandingPage />*/}
    </div>
  );
}

export default App;
